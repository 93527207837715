<template>
<the-header></the-header>
<div class="container">
    <b class="float" @click="formToggle">
        <router-link to="" >
            <h2 class="fa fa-plus my-float text-white"></h2>
        </router-link>
    </b>
    <div class="row">
        <search-action heading="Payment" @searchlist="getlist"></search-action>
    </div>
    <transition name="msg" mode="out-in">
    <strong class="text-success">{{msg}}</strong>
    </transition>

    <add-payment v-if="formVisible" @close="formToggle" @load="getlist" :update="UpdateData" @msg="showMessgae"></add-payment>

        <div class="py-2 table_div">
        <table class="table table-striped border">
        <thead>
            <tr>
            <th scope="col"></th>
            <th scope="col">Date</th>
            <th scope="col">Client Name</th>
            <th scope="col">Project Name</th>
            <th scope="col">Amount</th>
            <th scope="col">Image</th>
            <th scope="col"></th>
            </tr>
        </thead>
        <tbody>
            <tr v-if="getpayment.length==0"><td colspan="8" class="text-center">No Payment Are Avaiable.</td>
            </tr>
            <tr v-else v-for="data in getpayment" :key="data.int_payment_id">
            <th scope="row">{{data.int_payment_id}}</th>
            <td>{{data.dat_payment}}</td>
            <td>{{data.party_name}}</td>
            <td>{{data.txt_project_name}}</td>
            <td>{{data.dbl_amount}}</td>
            <td><a :href="data.txt_payment_image" target="_blank"><img :src="data.txt_payment_image"/></a></td>
            <td><base-button mode="icon" @click="updatePayment(data.int_payment_id)"><strong class="fas fa-edit" ></strong></base-button>
            <base-button mode="icon" @click="DeletePayment(data.int_payment_id)"><strong class="fas fa-trash" ></strong></base-button>
            </td>
            </tr>
            <tr style="text-align:right" >
                <td colspan="8" >
                    <ul class='pagination' >
                       <li class='page-item' :class="{active : item==getActive}" v-for="(item, index)  in getPagination" v-bind:key="index"><a href="#" class='page-link btn btn-sm' @click="pageredirect(item)" >{{getPaginationText[index]}}</a></li>
                    </ul>
                </td>
            </tr>
        </tbody>
    </table>
    </div>
</div>
</template>
<script>
import AddPayment from './AddPayment.vue'
export default {
    components:{
        AddPayment
    },data(){
        return{
            formVisible:false,
            UpdateData:false,
            msg:null,
            search:null,
        }
    },methods:{
        pageredirect(id){
            this.getlist(id)
        },
        getlist(pn=1,option=null){
            try{
                this.$store.dispatch('payment/getPayment',{page_no:pn,option:option});
            }catch(error){
               console.log('error'+error)
            }
        },
        updatePayment(id){
            this.formVisible=true;
            this.UpdateData=id;
        },
        formToggle(){
            this.UpdateData=false;
            this.formVisible=!this.formVisible
        },showMessgae(msg){
            this.msg=msg;
            setTimeout(()=>{
                this.msg=null;
            },3000);
        },DeletePayment(id){
            var result = confirm('Are you Sure To Delete ');
            if(result){
                try{
                this.$store.dispatch('payment/DeletePayemnt',{id:id});
                }catch(error){
                  console.log('error'+error)
                }
                setTimeout(()=>{
                    this.getlist();
                },200);
            }
        }

    },
    computed:{
        lastuse(){
            var Today = new Date();
            var today = Today.getTime();
            var mintues =  2 * 60000  ;
                if(this.$store.getters.isAuthenticate){
                    const lastUseTime = localStorage.getItem('lastuse');
                    var useMintues = (today - lastUseTime)/mintues;
                    const timeout = Math.round(useMintues);
                    if(timeout>0){
                        this.$store.dispatch('AutoLogout')
                    }else{      
                        localStorage.setItem('lastuse',today);
                    }
                }
                
            return true
        },
        getpayment(){
           
            return this.$store.getters['payment/getPayments'];
        },
        getActive(){
            return this.$store.getters['payment/getActive'];
        },
        getPagination(){
            return this.$store.getters['payment/getpagination'];
        },
        getPaginationText(){
            return this.$store.getters['payment/getpagination_text']
        }
    },
    created(){
        this.getlist();
    }
}
</script>